<template>
  <div class="profile-details">
    <div class="profile-content b-cover">
      <div class="pa-7">
        <div class="d-flex align-center justify-space-between">
          <span class="font-18 font-500 black--text">Basic Profile</span>
          <base-select style="max-width: 150px" placeholder="June 2021"> </base-select>
        </div>
        <p class="font-14 black-grey--text mb-0 d-block mt-1">
          This information can be edited from your profile page.
        </p>
      </div>
      <div class="b-bottom"></div>
      <div class="book-content">
        <v-row class="w-100 ma-0">
          <v-col cols=12 md=6 class="px-2">
            <!-- left top content -->
            <div class="px-7 pt-5 pb-7">
              <span class="font-14 font-500 black-grey--text d-block">My bookings summary</span>
              <span class="font-14 font-500 black-grey--text d-block mt-3">Activity name</span>
              <div class="d-flex align-center justify-space-between mt-1">
                <span class="black-grey--text d-inline-block font-14">- Adults x12</span>
                <span class="black--text d-inline-block font-16">L.L.525,000,00</span>
              </div>
              <div class="d-flex align-center justify-space-between mt-1">
                <span class="black-grey--text d-inline-block font-14"> - Teens x2</span>
                <span class="black--text d-inline-block font-16">L.L.525,000,00</span>
              </div>
              <div class="d-flex align-center justify-space-between mt-1">
                <span class="black-grey--text d-inline-block font-14"> - Kids x1</span>
                <span class="black--text d-inline-block font-16">L.L.525,000,00</span>
              </div>     
              <div class="d-flex align-center justify-space-between mt-1">
                <span class="black-grey--text d-inline-block font-14">- Pro photography x5</span>
                <span class="black--text d-inline-block font-16">L.L.525,000,00</span>
              </div>   
            </div>
            <div class="b-bottom"></div>

            <!-- left bottom content -->
            <div class="px-7 pt-5 pb-7">
              <span class="font-14 font-500 black-grey--text d-block mt-3">Activity name</span>
              <div class="d-flex align-center justify-space-between mt-1">
                <span class="black-grey--text d-inline-block font-14">- Adults x12</span>
                <span class="black--text d-inline-block font-16">L.L.525,000,00</span>
              </div>
              <div class="d-flex align-center justify-space-between mt-1">
                <span class="black-grey--text d-inline-block font-14"> - Teens x2</span>
                <span class="black--text d-inline-block font-16">L.L.525,000,00</span>
              </div>
              <div class="d-flex align-center justify-space-between mt-1">
                <span class="black-grey--text d-inline-block font-14"> - Kids x1</span>
                <span class="black--text d-inline-block font-16">L.L.525,000,00</span>
              </div>     
            </div>
          </v-col>

          <!-- right content -->
          <v-col cols=12 md=6 class="px-2 b-left">
            <div class="px-7 pt-5 pb-7 d-flex justify-space-between flex-column h-100">
              <div>
                <span class="font-14 font-500 black-grey--text d-block mt-3">Activity name</span>
                <span class="font-30 font-500 black--text d-block mt-3">L.L.525,000,00</span>
                <div class="d-flex align-center justify-space-between mt-1">
                  <span class="black-grey--text d-inline-block font-14">Total revenue</span>
                  <span class="black--text d-inline-block font-16">L.L.525,000,00</span>
                </div>  
              </div> 

              <!-- bottom details -->
              <div>
                <div class="d-flex align-center justify-space-between mt-1">
                  <span class="black-grey--text d-inline-block font-14">Amount you received</span>
                  <span class="black--text d-inline-block font-16">L.L.525,000,00</span>
                </div>
                <div class="d-flex align-center justify-space-between mt-1">
                  <span class="black-grey--text d-inline-block font-14">Amount received by Jedo</span>
                  <span class="black--text d-inline-block font-16">L.L.525,000,00</span>
                </div>
                <div class="d-flex align-start justify-space-between mt-1">
                  <div>

                  <span class="secondary--text d-inline-block font-14"> Jedo commission</span>
                  <span class="black-grey--text d-inline-block font-14"> 12.5% per booking x 345</span>
                  </div>
                  <span class="black--text d-inline-block font-16">L.L.525,000,00</span>
                </div>                   
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="profile-content b-cover mt-6">
      <div class="px-7 py-5">
          <span class="font-18 font-500 black--text">Completed Payments</span>
      </div>      
      <div class="d-bottom"></div>
      <div class="payment-item px-5 py-2 d-flex justify-space-between" v-for="(item,i) in [1,2,3,4,5]" :key="i">
        <div class="text-center mr-4">
          <span class="black-grey--text font-14 font-500 d-block">JUL</span>
          <span class="black-grey--text font-16 font-500 d-block">28</span>
        </div>
        <div class="w-100 d-flex justify-space-between align-center">
          <div class="text-left">
            <span class="black--text font-14 font-500 d-block">JFeb 2021 PaymentUL</span>
            <span class="black-grey--text font-12 d-block">Bank Transfer</span>
          </div>
          <div class="text-right">
            <span class="green--text font-14 font-500 d-block">+250.000</span>
            <span class="black--text font-14 font-500 d-block">L.L.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.profile-details {
  .profile-content {
    border-radius: 5px;
    background: white;
  }
  .payment-item{
    background: #EAEDF3;
    border-bottom: 1px solid white
  }
}
</style>

<template>
  <div class="profile-page pa-6">
    <v-row>
      <v-col md="4" col=12>
        <div class="profile-tabs b-cover">
          <img
            width="100%"
            height="300px"
            :src="require('@/assets/images/profile.png')"
            alt=""
          />
          <div class="px-7 py-4">
            <span class="d-block font-500 font-22">Ethel Harvey</span>
            <span class="d-block black-grey--text font-14 mt-1"
              >Caracas, Venezuela</span
            >
            <span class="d-block black-grey--text font-14"
              >Your time: 4:32 PM (GMT-4)</span
            >
          </div>
          <div class="b-bottom"></div>
          <div class="px-3 py-4">
            <div class="px-3 mb-2">
              <span class="black-grey--text font-14">Your Details</span>
            </div>
            <div
              v-for="(tab, i) in tabs"
              :key="i"
              class="profile-tab py-2 px-3 cursor-pointer"
              :class="tab.active ? 'profile-tab--active' : ''"
              @click="tabHandler(tab)"
            >
              <span class="white-grey--text font-14">{{ tab.title }}</span>
            </div>
          </div>
        </div>
      </v-col>
      <v-col md="8" cols=12>
        <div class="profile-content">
          <transition name="fade" mode="out-in">
            <profile-details
              v-if="activeContent == 'Account Settings'"
            ></profile-details>
            <profile-password
              v-if="activeContent == 'Password'"
            ></profile-password>
            <profile-booking
              v-if="activeContent == 'Bookings'"
            ></profile-booking>
          </transition>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProfileDetails from "@/components/profile/Details";
import ProfilePassword from "@/components/profile/Password";
import ProfileBooking from "@/components/profile/Booking";

export default {
  components: {
    ProfileDetails,
    ProfilePassword,
    ProfileBooking,
  },
  data: () => ({
    tabs: [
      { title: "Account Settings", active: true },
      { title: "Password", active: false },
      { title: "Bookings", active: false },
      { title: "Privacy", active: false },
    ],
    activeContent: "Account Settings",
  }),
  methods: {
    tabHandler(tab) {
      this.tabs.forEach((t) => {
        if (t.title == tab.title) {
          t.active = true;
          this.activeContent = tab.title;
        } else {
          t.active = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-page {
  background: #fbfbfd;
  .profile-tabs {
    background: white;
    border-radius: 5px;

    .profile-tab {
      transition: 0.3s;
      border-radius: 4px;
      span {
        transition: 0.3s;
      }

      &--active {
        background: $blue;

        span {
          color: white !important;
        }
      }
    }
  }

}
</style>

<template>
  <div class="profile-details">
    <div class="profile-content b-cover">
      <div class="pa-7">
        <span class="font-18 font-500 black--text">Basic Profile</span>
        <p class="font-14 black-grey--text mb-0 d-block mt-1">
          This information can be edited from your profile page.
        </p>
      </div>
      <div class="b-bottom"></div>
      <div class="pa-7">
        <v-row>
          <!-- first name -->
          <v-col cols="12" md="6">
            <label class="grey--text font-12 font-500">FIRST NAME </label>
            <base-input placeholder="First Name"> </base-input>
          </v-col>

          <!-- last name -->
          <v-col cols="12" md="6">
            <label class="grey--text font-12 font-500">LAST NAME </label>
            <base-input placeholder="First Name"> </base-input>
          </v-col>

          <!-- EMAIL ADDRESS -->
          <v-col cols="12" md="6">
            <label class="grey--text font-12 font-500">EMAIL ADDRESS </label>
            <base-input placeholder="EMAIL ADDRESS " rulesType="email">
            </base-input>
          </v-col>

          <!-- Phone Number -->
          <v-col cols="12" md="6">
            <label class="grey--text font-12 font-500">PHONE NUMBER </label>
            <base-input placeholder="PHONE NUMBER" rulesType="phone">
            </base-input>
          </v-col>

          <!-- CITY -->
          <v-col cols="12" md="6" class="py-1 pb-2">
            <label class="grey--text font-12 font-500"> CITY </label>
            <base-select placeholder="CITY"> </base-select>
          </v-col>

          <!-- CITY -->
          <v-col cols="12" md="6" class="py-1 pb-2">
            <label class="grey--text font-12 font-500"> CITY </label>
            <base-select placeholder="CITY"> </base-select>
          </v-col>

          <!-- Bio Intro -->
          <v-col cols="12" class="py-1 pb-2">
            <label class="grey--text font-12 font-500"> BIO INTRO </label>
            <base-textarea
              :rows="3"
              placeholder="Introduce yourself in a few lines.."
            >
            </base-textarea>
          </v-col>

          <!-- Bio -->
          <v-col cols="12" class="py-1 pb-2">
            <label class="grey--text font-12 font-500"> BIO</label>
            <base-textarea :rows="3" placeholder="Tell people about you">
            </base-textarea>
          </v-col>
        </v-row>
      </div>
      <div class="b-bottom"></div>
      <div class="px-7 py-5">
        <v-btn elevation="0" color="green" dark
          ><span class="font-14 d-inline-block px-2">Submit</span></v-btn
        >
      </div>
    </div>

    <!-- media section -->
    <div class="profile-content b-cover mt-6">
      <div class="pa-7">
        <span class="font-18 font-500 black--text">Media</span>
        <p class="font-14 black-grey--text mb-0 d-block mt-1">
          Add photos and videos from your previous experiences, even outside of
          Jedo.
        </p>
      </div>
      <!-- divier -->
      <div class="b-bottom"></div>

      <!-- content -->
      <div class="pa-7">content</div>

      <!-- divider -->
      <div class="b-bottom"></div>
      <div class="px-7 py-5">
        <v-btn elevation="0" color="green" dark
          ><span class="font-14 d-inline-block px-2">Upload Photos</span></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.profile-details {
  .profile-content {
    border-radius: 5px;
    background: white;
  }
}
</style>

<template>
  <div class="profile-details">
    <div class="profile-content b-cover">
      <div class="pa-7">
        <span class="font-18 font-500 black--text">Basic Profile</span>
        <p class="font-14 black-grey--text mb-0 d-block mt-1">
          This information can be edited from your profile page.
        </p>
      </div>
      <div class="b-bottom"></div>
      <div class="pa-7">
        <v-row>
          <!--Old Password -->
          <v-col cols="12" md="6">
            <label class="grey--text font-12 font-500">OLD PASSWORD</label>
            <base-input placeholder="OLD PASSWORD"> </base-input>
          </v-col>
          <v-col cols="12" md="6"> </v-col>

          <!-- New Password -->
          <v-col cols="12" md="6">
            <label class="grey--text font-12 font-500">NEW PASSWORD </label>
            <base-input placeholder="NEW PASSWORD"> </base-input>
          </v-col>

          <!-- RE-TYPE PASSWORD -->
          <v-col cols="12" md="6">
            <label class="grey--text font-12 font-500">RE-TYPE PASSWORD </label>
            <base-input placeholder="RE-TYPE PASSWORD" rulesType="email">
            </base-input>
          </v-col>
        </v-row>
      </div>
      <div class="b-bottom"></div>
      <div class="px-7 py-5">
        <v-btn elevation="0" color="green" dark
          ><span class="font-14 d-inline-block px-2">Save Settings</span></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.profile-details {
  .profile-content {
    border-radius: 5px;
    background: white;
  }
}
</style>
